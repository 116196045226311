<template>
  <div class="info_wrapper">
    <!-- 上部 start-->
    <div class="info_top">
      <div class="product_name">{{ productName }}</div>
      <van-cell-group>
        <van-cell class="my_cell">
          <div class="my_cell_box">
            <div class="my_cell_label">投保方案</div>
            <div class="my_cell_content">
              <!-- <div
                :class="`content_case ${
                  currentCaseId === item.id ? 'active' : ''
                }`"
                v-for="(item, index) in productCases"
                :key="item.id"
                @click="handleCaseChange(item.id, index)"
              > -->
              {{ productCases.propertyName }}
              <!-- </div> -->
            </div>
          </div>
        </van-cell>
        <van-cell class="my_cell" @click="showActionSheet = true">
          <div class="my_cell_box">
            <div class="my_cell_label">保险期间</div>
            <div class="my_cell_content">
              <div class="content">{{ actionTitle }}</div>
              <!-- <div class="iconfont icon-arrow icon_arrow_right"></div> -->
            </div>
          </div>
        </van-cell>
        <!-- <van-action-sheet
          v-model="showActionSheet"
          :actions="actions"
          cancel-text="取消"
          safe-area-inset-bottom
          @select="onSelect"
        /> -->
        <van-cell class="my_cell" @click="showCalendar = true">
          <div class="my_cell_box">
            <div class="my_cell_label">起保日期</div>
            <div class="my_cell_content">
              <div class="content_date">
                <div class="date_msg">{{ startDate }}</div>
                <div class="iconfont icon-calendar icon_calendar"></div>
              </div>
            </div>
          </div>
        </van-cell>
        <van-calendar
          color="#28CC77"
          :min-date="minChooseDate"
          v-model="showCalendar"
          @confirm="onCalendarConfirm"
        />
      </van-cell-group>
    </div>
    <van-form>
      <div class="info_header">
        <div class="header_msg">填写投保信息</div>
      </div>
      <div class="policy_holder_info">
        <div class="policy_holder_info_header">
          <span class="policy_holder_info_tag">1</span>
          <div class="policy_holder_info_msg">投保人（雇主）</div>
        </div>
        <van-field
          class="my_insured_field"
          v-model="policyHolderInfo.personalName"
          name="personalName"
          label="姓名"
          placeholder="请输入投保人姓名"
          label-class="my_insured_label"
          required
        />
        <van-field
          class="my_insured_field"
          v-model="policyHolderInfo.personalIdCardNum"
          name="personalIdCardNum"
          label="证件号码"
          placeholder="请输入保人证件号码"
          label-class="my_insured_label"
          required
        />
        <van-field
          class="my_insured_field"
          v-model="policyHolderInfo.personalPhoneNum"
          name="personalPhoneNum"
          label="手机号码"
          placeholder="请输入手机号码"
          label-class="my_insured_label"
          required
        />
        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="provinceValue"
          label-class="my_field_label"
          label="服务地址(省)"
          required
          placeholder="请选择省"
          @click="showAreaProvince = true"
        />
        <van-popup v-model="showAreaProvince" position="bottom">
          <van-picker
            title="选择省"
            show-toolbar
            :columns="provinceList"
            @confirm="onProvinceConfirm"
            @cancel="showAreaProvince = false"
          />
        </van-popup>
        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="cityValue"
          label-class="my_field_label"
          label="服务地址(市)"
          required
          placeholder="请选择市"
          @click="showAreaCity = true"
        />
        <van-popup v-model="showAreaCity" position="bottom">
          <van-picker
            title="选择市"
            show-toolbar
            :columns="cityList"
            @confirm="onCityConfirm"
            @cancel="showAreaCity = false"
          />
        </van-popup>
        <van-field
          class="my_field"
          readonly
          clickable
          name="area"
          :value="countryValue"
          label-class="my_field_label"
          label="服务地址(区)"
          required
          placeholder="请选择区"
          @click="showAreaCounty = true"
        />
        <van-popup v-model="showAreaCounty" position="bottom">
          <van-picker
            title="选择区"
            show-toolbar
            :columns="countyList"
            @confirm="onCountyConfirm"
            @cancel="showAreaCounty = false"
          />
        </van-popup>
<!--        <van-field
          class="my_insured_field"
          v-model="areaInfo.address"
          name="address"
          label="详细地址"
          placeholder="请输入详细地址"
          label-class="my_insured_label"
          required
        /> -->
      </div>
      <!-- 被保人信息 -->
      <div class="insured_info">
        <div class="insured_info_header">
          <span class="insured_info_tag">2</span>
          <div class="insured_info_msg">被保险人（阿姨）</div>
        </div>
        <ul class="insured_info_list">
          <li
            class="insured_info_list_item"
            v-for="(item, index) of insuredInfoList"
            :key="index"
          >
            <p>被保人{{ index + 1 }}</p>
            <p>{{ item.insuredName }}</p>
            <p>
              <span @click="handleWrite(item)">编辑</span>
              <span @click="delOne(index)">删除</span>
            </p>
          </li>
        </ul>

        <div class="insured_info_btn" v-show="!this.insuredInfoList.length > 0" @click="handleWrite()">添加被保人</div>
        <van-popup
          v-model="showAddInsured"
          position="bottom"
          class="tip_pop"
          @close="test"
        >
          <InsuredInfoItem
            v-on:insuredData="getInsuredData($event)"
            v-on:close="closrInsuredPopup($event)"
            v-if="showAddInsured"
            :insuredInfo="insuredInfo"
            :isItemEdit="isItemEdit"
          />
        </van-popup>
      </div>
      <div class="form_bottom_box">
        <div class="check_box">
          <van-checkbox
            icon-size=".26rem"
            checked-color="#28cc77"
            v-model="checked"
            label-disabled
          >
            <span>我已阅读</span>
            <span class="link" @click="isPolicyDeclarationPopup = true"
              >《投保须知及声明》、</span
            >
            <span class="link" @click="isTermsDetailPopup = true"
              >《条款详情》</span
            >
          </van-checkbox>
        </div>
        <!-- pdf弹出框 -->
        <van-action-sheet
          v-model="showPdfFlag"
          title="投保须知及声明"
          class="actionBox"
        >
          <div class="content">
            <div class="pdf_content"></div>
          </div>
        </van-action-sheet>
        <div class="bottom_handle">
          <div class="price">{{ `${insuredInfoList.length * total}元` }}</div>
          <van-button class="handle_btn" @click="handleConfirm">立即投保</van-button>
        </div>
      </div>
    </van-form>

    <!-- 《投保须知及声明》、 -->
    <van-popup
      v-model="isPolicyDeclarationPopup"
      position="bottom"
      class="tip_pop"
    >
      <policy-declaration :productId="productId"></policy-declaration>
    </van-popup>
    <!-- 《条款详情》 -->
    <van-popup v-model="isTermsDetailPopup" position="bottom" class="tip_pop">
      <terms-detail :productId="productId"></terms-detail>
    </van-popup>

    <van-overlay :show="isAuthentication"
      ><div class="policy-inform-authentication">
        身份验证中,请稍等........
      </div></van-overlay
    >
  </div>
</template>

<script>
import "./PolicyInfoForm.less";
import { getProductDetail, getPremium } from '../../api/api'
import {
  Calendar,
  Cell,
  CellGroup,
  Field,
  Form,
  Popup,
  Notify,
  Checkbox,
  ActionSheet,
  Picker,
  Overlay,
  Dialog,
  Button
} from "vant";
import InsuredInfoItem from "@/components/InsuredInfoItem/InsuredInfoItem.vue";
import { verifyCardNo, isMobile, getCardNoSex, getBirthdayFromIdCard } from "../../utils/verifyUtils";
import dayjs from "../../libs/js/day";
import { saveOrderPolicy, getAreaList, checkIfVerify, getPayInfo } from '../../api/api';
import PolicyDeclaration from '../ProductDetail/PolicyDeclaration/PolicyDeclaration.vue';
import Termsdetail from '../ProductDetail/TermsDetail/TermsDetail.vue';
import wx from 'weixin-js-sdk';
export default {
  name: "PolicyInfoForm",
  data() {
    return {
      timer: '',
      showPdfFlag: false,
      currentCaseId: 0,
      currentIndex: 0,
      showCalendar: false,
      policyHolderType: "1",
      startDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
      minChooseDate: new Date(dayjs().add(1, "day")),
      // 被保人信息列表
      insuredInfoList: [],
      insuredInfo: {},
      checked: false,
      showActionSheet: false,
      productName: '', // 产品名称
      productCases: [], // 投保方案
      actions: [], // 保险期间
      actionTitle: '', // 保险期间文案
      actionId: '',
      insurances: [],
      total: '', // 价格
      productId: this.$route.query.productId,
      productCode: '',
      userInfo: {},
      isAuthentication: false,

      policyHolderInfo: {
        name: '',
        cardNumber: '',
        tel: ''
      }, //投保人信息
      showAddInsured: false,   //添加被保人弹窗
      isItemEdit: false,   //判断是否编辑状态
      isPolicyDeclarationPopup: false,   //投保声明及须知弹窗
      isTermsDetailPopup: false,   //条款列表弹窗

      provinceValue: "", // 省市区
      cityValue: "", // 省市区
      countryValue: "", // 省市区
      showArea: false,
      areaList: {
        province_list: [],
        city_list: [],
        county_list: [],
      }, // 数据格式见 Area 组件文档
      defauleCountyCode: "",
      areaInfo: {
        provinceCode: '',
        cityCode: '',
        districtCode: '',
        address: ''
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      showAreaProvince: false,
      showAreaCounty: false,
      showAreaCity: false,
      planCode: this.$route.query.planCode,
      sourceType: '',
      openId: '',
      unitId: '',
      authUrl: '',
	  customerId: this.$route.query.customerId,
    };
  },
  components: {
    "van-cell": Cell,
    "van-cell-group": CellGroup,
    "van-form": Form,
    "van-field": Field,
    "van-checkbox": Checkbox,
    "van-popup": Popup,
    "van-calendar": Calendar,
    "van-action-sheet": ActionSheet,
    InsuredInfoItem,
    'policy-declaration': PolicyDeclaration,
    'terms-detail': Termsdetail,
    "van-picker": Picker,
    "van-overlay": Overlay,
	"van-button": Button
  },
  mounted() {
    console.log(localStorage.getItem('formInfo0'));
    const data = JSON.parse(localStorage.getItem('formInfo0'));
    console.log(data)
    if (data) {
      this.productId = data.productId;
      this.planCode = data.planCode;
      this.startDate = data.startDate;
      this.policyHolderInfo = JSON.parse(data.policyHolderInfo);
      this.provinceValue = data.provinceValue;
      this.cityValue = data.cityValue;
      this.countryValue = data.countryValue;
      this.areaList = JSON.parse(data.areaList);
      this.insuredInfoList = JSON.parse(data.insuredInfoList);
      this.policyId = JSON.parse(data.policyId);
      this.areaInfo = JSON.parse(data.areaInfo);
      this.sourceType = data.sourceType;
      this.openId = data.openId;
      this.unitId = data.unitId;
      this.authUrl = data.authUrl;
	  this.customerId = data.customerId;
      this.checkIfVerify();
      // localStorage.removeItem('formInfo0');
      setTimeout(() => {

        localStorage.removeItem('formInfo0');
      }, 5000);
    }

    this.getProductInsuredAttr();
    getAreaList({ code: 0 }).then(res => {
      res.data.forEach(res => {
        this.provinceList.push(res.name);
        this.areaList.province_list.push(res);
      });
    });
  },

  destroyed() {
    clearTimeout(this.timer);
  },

  methods: {
    //   获取配置
    async getProductInsuredAttr() {
      const { data } = await getProductDetail({
        pid: this.productId,
        planCode: this.planCode
      });

      data.insurances = JSON.parse(data.insurances);
      data.ext = JSON.parse(data.ext);
      data.liability = JSON.parse(data.liability);
      data.company = JSON.parse(data.company);
      // const resData = data.body.productAttributes;
      this.productCode = data.insurances.code;
      this.productName = data.productName;

      this.productCases = data.insurances.insuranceAmount;
      this.actions = data.insurances.insurancePeriod;
      this.actions1 = data.insurances.insurancePeriod;

      this.insurances = data.insurances;
      if (this.actions.length != 0) this.actionTitle = this.actions[0].propertyName
      if (this.actions.length != 0) this.actionId = this.actions[0].id
      if (this.productCases.length != 0) this.currentCaseId = this.productCases.id
      if (+this.productId === 157 && +this.currentIndex === 0) {
        this.actions = [this.actions1[0]]
        this.actionId = this.actions1[0].id
        this.actionTitle = this.actions1[0].propertyName
      }
      this.getPremium();
    },
    // 获取金额
    async getPremium() {
      const { actions, actionId, insurances } = this.$data
      const paramsData = {
        products: [
          {
            amount: this.planCode,
            copys: 1,
            insuranceId: insurances.id,
            insuranceterm: actions.length != 0 ? actions.find(item => item.id === actionId).propertyValue : 0,
            payterm: insurances.payPeriod[0].propertyValue,
            productId: this.productId,
          },
        ],
        customers: [],
      }
      const data = await getPremium(paramsData);
      console.log(JSON.parse(data.data.policy));
      this.total = JSON.parse(data.data.policy).total;
    },
    // 选择年月触发函数
    onSelect(action) {
      this.actionId = action.id
      this.actionTitle = action.name
      this.showActionSheet = false;
      this.getPremium()
    },

    // 点击方案处理函数
    handleCaseChange(id, index) {
      if (id === this.currentCaseId) return;
      this.currentIndex = index
      this.currentCaseId = id;
      if (+this.productId === 157 && +this.currentIndex === 0) {
        this.actions = [this.actions1[0]]
        this.actionId = this.actions1[0].id
        this.actionTitle = this.actions1[0].propertyName
      } else {
        this.actions = this.actions1
      }
      this.getPremium()
    },
    onCalendarConfirm(date) {
      this.startDate = dayjs(date).format("YYYY-MM-DD");
      this.showCalendar = false;
    },

    closrInsuredPopup(e) {
      this.isItemEdit = false;
      if (e) this.showAddInsured = false;
      else this.insuredInfo = {}
    },

    getInsuredData(e) {
      if (!this.isItemEdit) {
        e.id = this.insuredInfoList.length;
        this.insuredInfoList.push(e);
      }
      else
        // this.insuredInfoList.filter(res => res.id == e.id)[0] = e;
        this.insuredInfoList.forEach((res, index) => {
          if (res.id === e.id)
            this.insuredInfoList[index] = e;
        })
    },

    handleWrite(item) {
      if (item) this.isItemEdit = true;
      this.showAddInsured = true;
      this.insuredInfo = item || {};
    },
    test() {
      this.isItemEdit = false;
    },
    delOne(index) {
      this.insuredInfoList.splice(index, 1)
    },
    // 点击立即投保
    handleConfirm() {
      const errMsg = this.verifyFormData();
      if (errMsg) {
        // 校验投保人
        Notify(errMsg);
      } else {
        if (!this.insuredInfoList.length) {
          Notify("请添加被保人信息");
        } else {
          if (!this.checked) {
            Notify(`请确认已勾选《投保须知及声明》《条款详情》`);
          } else {
            this.saveOrderPolicy()
          }
        }
      }
    },
    // 投保人表单校验
    verifyFormData() {
      const {
        personalName,
        personalIdCardNum,
        personalPhoneNum,
      } = this.policyHolderInfo;
      // 个人投保校验规则
      let verifyRules = [];
      verifyRules = [
        {
          errMsg: "请输入投保人姓名",
          value: personalName,
          type: "personalName",
        },
        {
          errMsg: "请输入投保人的证件号码",
          value: personalIdCardNum,
          verifyMsg: "身份证格式不正确",
          type: "personalIdCardNum",
        },
        {
          errMsg: "请输入投保人手机号",
          value: personalPhoneNum,
          verifyMsg: "手机号格式不正确",
          type: "personalPhoneNum",
        },

        {
          errMsg: "请选择省份",
          value: this.areaInfo.provinceCode,
          verifyMsg: "没选择省份",
          type: "provinceCode",
        },
        {
          errMsg: "请选择市",
          value: this.areaInfo.cityCode,
          verifyMsg: "没选择市",
          type: "cityCode",
        },
        {
          errMsg: "请选择区",
          value: this.areaInfo.districtCode,
          verifyMsg: "没选择区",
          type: "districtCode",
        },
        // {
        //   errMsg: "请输入详细地址",
        //   value: this.areaInfo.address,
        //   verifyMsg: "详细地址没输入",
        //   type: "address",
        // },
      ];
      return this.getErrorMsg(verifyRules);
    },
    // 错误信息提示
    getErrorMsg(verifyRules) {
      let errorArray = [];
      verifyRules.map((item) => {
        if (!item.value) {
          errorArray.push(item.errMsg);
        } else if (item.verifyMsg) {
          if (item.type === "personalIdCardNum") {
            const personalIdCardNum = verifyCardNo(item.value);
            if (personalIdCardNum) {
              errorArray.push(item.verifyMsg);
            }
          }
          if (item.type === "personalPhoneNum") {
            const personalPhoneNum = isMobile(item.value);
            if (!personalPhoneNum) {
              errorArray.push(item.verifyMsg);
            }
          }
        }
      });
      if (!errorArray.length) return false;
      return errorArray[0];
    },
    handleInsuredArrayData() {
      const { insuredInfoList } = this.$data
      let insuredArray = [];
      insuredInfoList.forEach(item => {
        insuredArray.push({
          name: item.insuredName,
          idType: item.insuredCertificatId,
          idNumber: item.insuredIdCardNum,
          gender: +item.insuredCertificatId === 0 ? getCardNoSex(item.insuredIdCardNum) : item.insuredGender,
          birthDate: +item.insuredCertificatId === 0 ? getBirthdayFromIdCard(item.insuredIdCardNum) : item.birthday
        })
      })
      return insuredArray
    },
    //通过身份证获取出生年月和性别
    idNumberGetInfo(idCard) {
      var birthday = "";
      if (idCard != null && idCard != "") {
        if (idCard.length == 15) {
          birthday = "19" + idCard.slice(6, 12);
        } else if (idCard.length == 18) {
          birthday = idCard.slice(6, 14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      }

      var sexStr = '';
      if (parseInt(idCard.slice(-2, -1)) % 2 == 1) {
        sexStr = 1;
      }
      else {
        sexStr = 0;
      }
      return {
        birthday,
        sexStr
      }
    },
    weixinPay(data) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady(data));
        }
      } else {
        this.onBridgeReady(data);
      }
    },
    onBridgeReady(data) {
      var that = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.packageValue, //订单详情扩展字符串
          signType: data.signType, //微信签名方式：
          paySign: data.paySign, //微信签名
        },
        function (res) {
          console.log('payment', res);
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // Notify("支付成功");
            // clearTimeout(this.timer);
            that.$router.push({
              path: '/PaymentFinish',
            });
            localStorage.removeItem('formInfo0');
            // ...
          } else {
            alert("支付失败！");
          }
        }
      );
    },

    dateAdd(date, addYear = 0, addMonth = 0, addDay = 0) {
      console.log(new Date(`${date.getFullYear() + addYear}/${date.getMonth() + 1 + addMonth}/${date.getDate()}`));

      date.setDate(date.getDate() + addDay);

      const newDate = this.getDate(new Date(`${date.getFullYear() + addYear}/${date.getMonth() + 1 + addMonth}/${date.getDate()}`));

      return newDate;
    },
    getDate(date) {
      console.log(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return year + "/" + month + "/" + day;
    },
    saveOrderPolicy() {
      //计算保险有效日期
      const sourceType = this.$route.query.sourceType || this.sourceType;
	  let openId = this.openId
	  let unitId = this.unitId
	  let loginInfo = localStorage.getItem('loginInfo')
	  if(!sourceType && loginInfo){
		  loginInfo = JSON.parse(loginInfo)
		  openId = loginInfo.openId
		  unitId = loginInfo.unitId
	  }else if(this.$route.query.openId && this.$route.query.unitId){
		   openId = this.$route.query.openId
		   unitId = this.$route.query.unitId
	  }
      // const openId = !sourceType ? JSON.parse(localStorage.getItem('loginInfo')).openId : this.$route.query.openId;
      // const unitId = !sourceType ? JSON.parse(localStorage.getItem('loginInfo')).unitId : this.$route.query.unitId;

      const day = this.actions.find(item => item.id === this.actionId).propertyValue;
      let expireDate;
      switch (day) {
        case 'TYONE': expireDate = this.dateAdd(new Date(this.startDate), 1, 0, -1); break;
        case 'TMONE': expireDate = this.dateAdd(new Date(this.startDate), 0, 1, -1); break;
      }

      const params = {
		"customerId":this.customerId || "",
        "totalPremium": this.insuredInfoList.length * this.total,
        "planCode": this.planCode,
        "creator": "1",
        "companyCode": "20",
        "agencyPolicyRef": "",
        "productCode": this.productCode,
        "effectiveDate": this.startDate,
        "expireDate": expireDate,
        "destination": "",
        "remark": "",
        "groupSize": "",
        "premiumCalType": "",
        "productId": this.$route.query.productId,
        "sourceType": !sourceType ? 'H5' : 'MINI',
        "nationAreaType": "",
        "areaType": "",
        "optionalFlag": "",
        "insurancePeriod": "",
        "ownerName": "",
        "pieces": "",
        "reasonRemark": "",
        "transactionTotalPremium": this.insuredInfoList.length * this.total,
        "productName": this.productName,
        "ownerId": 0,
        "agencyUsername": "",
        "saleId": 0,
        "oldPolicyNo": "",
        "insurePolicyNo": "",
        "notifyUrl": "",
        "tradeType": "NO_PAY",
        "insuringWay": 0,
        "insuredDTOList": [],
        "policyHolder": {
          "name": this.policyHolderInfo.personalName,
          "idNumber": this.policyHolderInfo.personalIdCardNum,
          "address": '　',
          "districtCode": this.areaInfo.districtCode,
          "cityCode": this.areaInfo.cityCode,
          "provinceCode": this.areaInfo.provinceCode,
          "birthDate": this.idNumberGetInfo(this.policyHolderInfo.personalIdCardNum).birthday,
          "gender": this.idNumberGetInfo(this.policyHolderInfo.personalIdCardNum).sexStr == 1 ? 'M' : 'F',
          "type": "I",
          "idType": "ID",
          "companyCode": "PA03",
          "telephone": this.policyHolderInfo.personalPhoneNum
        },
        "unionId": unitId,
        "payType": "WECHAT",
        "inviteCode": this.$route.query.inviteCode || '000000000000',
        "openid": openId
      }
      this.insuredInfoList.forEach(res => {
        params.insuredDTOList.push({
          "policyId": '',
          "type": "",
          "name": res.insuredName,
          "idType": "ID",
          "idNumber": res.insuredIdCardNum,
          "mobile": res.insuredTel,
          "email": "",
          "birthDate": this.idNumberGetInfo(res.insuredIdCardNum).birthday,
          "gender": this.idNumberGetInfo(res.insuredIdCardNum).sexStr == 1 ? 'M' : 'F',
          "beneficialType": "",
          "creator": "",
          "modifier": "",
          "occupationCode": "",
          "occupationName": "",
          "relationship": "",
          "status": "",
          "markStatus": "",
          "operType": "",
          "upMarks": "",
          "epolicyPath": "",
          "effectiveDate": this.startDate,
          "expireDate": "",
          "policyNo": ""
        })
      })
      saveOrderPolicy(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          const data = res.data[0].verifiedVo;
          localStorage.setItem('formInfo0', JSON.stringify({
            productId: this.productId,
            planCode: this.planCode,
            startDate: this.startDate,
            policyHolderInfo: JSON.stringify(this.policyHolderInfo),
            provinceValue: this.provinceValue, // 省市区
            cityValue: this.cityValue, // 省市区
            countryValue: this.countryValue, // 省市区
            areaList: JSON.stringify(this.areaList),
            insuredInfoList: JSON.stringify(this.insuredInfoList),
            policyId: res.data[0].policyId,
            areaInfo: JSON.stringify(this.areaInfo),
            sourceType: this.$route.query.sourceType,
            openId: openId,
            unitId: unitId,
            authUrl: data.authUrl,
			customerId: this.customerId || ""
          }))

          window.location.href = data.authUrl;
        } else
        {
          Notify(res.message);
        }
        //test111
        // this.checkIfVerify(res);
      })
    },
    checkIfVerify() {
      // const { sourceType } = this.$route.query;
      // const openId = !sourceType ? JSON.parse(localStorage.getItem('loginInfo')).openId : this.$route.query.openId;
      // const data = res.data[0].verifiedVo;
      // if (data.success) {
      //   const win = window.open(data.authUrl);

      //   this.isAuthentication = true;
      //   let index = 0;
      //   //监听窗口是否关闭
      //   var timer = setInterval(() => {
      //     if (win.closed) {
      //       clearInterval(timer);
      //       //轮询是否认证成功

      //     }
      //   }, 1000);
      // }
      this.isAuthentication = true;
      let index = 0;
      const timer1 = setInterval(() => {
        index++;
        if (index > 10) {
          clearInterval(timer1);
          this.isAuthentication = false;
          Dialog.confirm({
            title: '认证失败',
            message: '认证失败,是否重试'
          }).then(() => {
            // on confirm
            localStorage.setItem('formInfo0', JSON.stringify({
              productId: this.productId,
              planCode: this.planCode,
              startDate: this.startDate,
              policyHolderInfo: JSON.stringify(this.policyHolderInfo),
              provinceValue: this.provinceValue, // 省市区
              cityValue: this.cityValue, // 省市区
              countryValue: this.countryValue, // 省市区
              areaList: JSON.stringify(this.areaList),
              insuredInfoList: JSON.stringify(this.insuredInfoList),
              policyId: this.policyId,
              areaInfo: JSON.stringify(this.areaInfo),
              sourceType: this.sourceType,
              openId: this.openId,
              unitId: this.unitId
            }))

            window.location.href = this.authUrl;
            // this.checkIfVerify();
          }).catch(() => {
            // on cancel
          });
          return;
        }
        checkIfVerify({
          openId: this.openId,
          policyId: this.policyId,
          unionId: this.unitId
        }).then(statusInfo => {
          if (statusInfo.data) {
            this.isAuthentication = false;
            clearInterval(timer1);
            getPayInfo({
              openId: this.openId,
              policyId: this.policyId,
              unionId: this.unitId
            }).then(payInfo => {
				console.log('payInfo',payInfo)
              let data = payInfo.data.request.resultStr;
              localStorage.removeItem('formInfo0');
              data = JSON.parse(data);

              if (!this.sourceType)
                this.weixinPay(data);
              else {
                //跳去小程序
                wx.miniProgram.redirectTo({
                  url: `../pay/pay?appId=${data.appId}&timeStamp=${data.timeStamp}&nonceStr=${data.nonceStr}&package=${data.packageValue.split('=')[0]}&prepayId=${data.packageValue.split('=')[1]}&signType=${data.signType}&paySign=${data.paySign}`,
                })
              }
            })
          }
        });
      }, 1500)
    },
    onProvinceConfirm(value) {
      const province = this.areaList.province_list.filter((res) => res.name === value)[0];
      this.areaInfo.provinceCode = province.code;
      this.provinceValue = value;
      getAreaList({ code: province.code }).then(res => {
        this.cityList = [];
        this.areaList.city_list = [];
        res.data.forEach(res => {
          this.cityList.push(res.name);
          this.areaList.city_list.push(res);
        });
      });
      this.showAreaProvince = false;
    },
    onCityConfirm(value) {
      const city = this.areaList.city_list.filter((res) => res.name === value)[0];
      this.areaInfo.cityCode = city.code;
      this.cityValue = value;
      getAreaList({ code: city.code }).then(res => {
        this.countyList = [];
        this.areaList.county_list = [];
        res.data.forEach(res => {
          this.countyList.push(res.name);
          this.areaList.county_list.push(res);
        });

        console.log(this.countyList);
      });
      this.showAreaCity = false;
    },
    onCountyConfirm(value) {
      const county = this.areaList.county_list.filter((res) => res.name === value)[0];
      this.areaInfo.districtCode = county.code;
      this.countryValue = value;
      this.showAreaCounty = false;
    },
  },
};
</script>
