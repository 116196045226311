<template>
  <div class="insured_info_item">
    <van-cell>
      <div class="item_title_box">
        <div class="insured_index">添加被保人</div>
      </div>
    </van-cell>
    <!-- <div class="insured_info_item_selected">
      <div class="insured_info_item_selected_title">为谁投保（单选）</div>
      <ul>
        <li
          @click="selected(item)"
          v-for="(item, index) of userList"
          :class="{ selected: insuredInfo.selectedPeople == item }"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div> -->

    <van-field
      class="my_insured_field"
      v-model="tempData.insuredName"
      name="insuredName"
      label="姓名"
      placeholder="请输入被保人姓名"
      label-class="my_insured_label"
      required
    />

    <van-field
      class="my_insured_field"
      v-model="tempData.insuredIdCardNum"
      name="insuredIdCardNum"
      label="证件号码"
      placeholder="请输入被保人证件号码"
      label-class="my_insured_label"
      required
    />
    <van-field
      class="my_insured_field"
      v-model="tempData.insuredTel"
      name="insuredTel"
      label="手机号码"
      placeholder="请输入手机号码"
      label-class="my_insured_label"
      required
    />

    <!-- <van-cell class="my_cell my_insured_field" @click="showCalendar = true">
      <div class="my_cell_box">
        <div class="my_cell_label">起保日期</div>
        <div class="my_cell_content">
          <div class="content_date">
            <div class="date_msg">{{ startDate }}</div>
            <div class="iconfont icon-calendar icon_calendar"></div>
          </div>
        </div>
      </div>
    </van-cell>
    <van-calendar
      color="#28CC77"
      :min-date="minChooseDate"
      v-model="showCalendar"
      @confirm="onCalendarConfirm"
    /> -->

    <!-- <van-cell class="my_cell my_insured_field" @click="showActionSheet = true">
      <div class="my_cell_box">
        <div class="my_cell_label">性别</div>
        <div class="my_cell_content">
          <div class="content">{{gender}}</div>
        </div>
      </div>
    </van-cell>
    <van-action-sheet
      v-model="showActionSheet"
      :actions="actions"
      cancel-text="取消"
      safe-area-inset-bottom
      @select="onSelect"
    /> -->

    <ul class="insured_btn_list">
      <li @click="close">返回</li>
      <!-- <li @click="confirm(false)" v-if="!isItemEdit">添加后继续</li> -->
      <li @click="confirm(true)">{{ isItemEdit ? "确认" : "添加" }}</li>
    </ul>
  </div>
</template>

<script>
import './InsuredInfoItem.less';
import { Cell, Field, Notify } from 'vant';
import { verifyCardNo, isMobile } from "../../utils/verifyUtils";
import dayjs from "../../libs/js/day";
export default {
  name: 'InsuredInfoItem',
  props: {
    insuredInfo: {
      type: Object,
      default: function () {
        return {
          selectedPeople: this.userList[0],
          insuredTel: '',
          insuredIdCardNum: '',
          insuredName: '',
        }
      }
    },
    isItemEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userList: [
        '本人',
        '配偶',
        '子女',
        '父母',
        '雇员',
        '其他'
      ],
      inSuredPass: true,
      errMsg: '',
      showCalendar: false,

      startDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
      minChooseDate: new Date(dayjs().add(1, "day")),
      showActionSheet: false,
      actions: [
        { name: '男' },
        { name: '女' },
      ],
      gender: '男',
      tempData: {
        insuredTel: '',
        insuredIdCardNum: '',
        insuredName: '',
      }
    }
  },
  components: {
    'van-cell': Cell,
    'van-field': Field,
  },
  mounted() {
    this.tempData = JSON.parse(JSON.stringify(this.insuredInfo));
  },
  methods: {
    onSelect(item) {
      this.gender = item.name;
      this.showActionSheet = false;
    },
    selected(value) {
      this.$set(this.insuredInfo, 'selectedPeople', value);
    },
    onCalendarConfirm(date) {
      this.startDate = dayjs(date).format("YYYY-MM-DD");
      this.showCalendar = false;
    },
    close() {

      this.$emit('close', true);
    },
    verifyinsuredFormData() {
      this.inSuredPass = true;
      if (!this.tempData.insuredName) {
        this.inSuredPass = false;
        this.errMsg = `请输入被保人姓名`;
      }
      const personalIdCardNum = verifyCardNo(this.tempData.insuredIdCardNum)
      if (personalIdCardNum) {
        this.inSuredPass = false;
        this.errMsg = personalIdCardNum;
      }

      const personalPhoneNum = isMobile(this.tempData.insuredTel);
      if (!personalPhoneNum) {
        this.inSuredPass = false;
        this.errMsg = '手机号格式不正确';
      }

      if (!this.inSuredPass && this.errMsg) {
        Notify(this.errMsg);
        return;
      }
    },
    confirm(isBack) {
      this.verifyinsuredFormData();
      if (this.inSuredPass) {
        this.$emit('insuredData', this.tempData);

        this.tempData = {
          insuredTel: '',
          insuredIdCardNum: '',
          insuredName: '',
        }

        this.$emit('close', isBack);
      }
    }
  },
}
</script>

<style>
</style>